*,
*::after,
*::before {
    box-sizing: border-box;
}

:root {
    --vh100: calc(var(--vh, 1vh) * 100);
    --color-primary: #2b6fb4;
}

.map {
    width: 100%;
    height: 85vh;
    height: calc(var(--vh100) - 66px);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.map .alert {
    position: absolute;
    top: 90px;
    left: 24px;
    right: 24px;
    background-color: #fff;
    z-index: 1000;
    text-align: center;
    font-size: 14px;
    line-height: 2;
    padding: 16px;
}

.map .alert button {
    font-size: 16px;
    color: var(--color-primary);
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
}

.leaflet-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.button-location {
    position: absolute;
    z-index: 1000;
    bottom: 24px;
    right: 24px;
    background: none;
    border: none;
    font-size: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 0;
}
.button-location.bus {
    right: 80px;
}
.button-location svg {
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (min-width: 768px) {
    .button-location {
        bottom: 48px;
        right: 48px;
    }
    .button-location.bus {
        right: 104px;
    }
}

@media screen and (min-width: 992px) {
    .map .alert {
        top: 24px;
    }
}

/* POPUP */
.leaflet-popup-content-wrapper {
    padding: 0;
    overflow: hidden;
    box-shadow: none;
}
.leaflet-popup {
    width: 280px;
    overflow: hidden;
}

.leaflet-popup-content {
    margin: 0;
    width: 100% !important;
    height: 100%;
}

.leaflet-popup-content p {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0 8px;
}

.leaflet-popup .popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leaflet-popup img {
    width: 100%;
    height: auto;
}

/* TYPOGRAPHY */
.text-uppercase {
    text-transform: uppercase;
}
.text-bold {
    font-weight: bold;
}
